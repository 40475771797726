<template>
  <div class="main">
    <div class="text-center">
      <img
        src="https://nixloc.com.br/wp-content/themes/nix-loc/images/logo_nixweb.png"
      />
      <br />
      <div class="div-title">
        <br />
        <span class="title">Guia de Apresentação</span>
      </div>
    </div>
    <br />
    <Molded>
      <RadioGroup
        title="Qual ramo de atividade?"
        :options="[
          {
            text: 'Audiovisual, Banheiro Quimico, Container, Caçamba, Estrutura',
            value: 'programmed',
          },
          { text: 'Construção Civil, Hospitalar', value: 'immediate' },
        ]"
        v-model="business"
      />
      <CheckboxGroup
        title="O que precisa que o nosso software controle?"
        :markFormDirty="false"
        :options="options"
        v-model="modules"
      />
    </Molded>
    <br />
    <Molded>
      <InputText
        title="1º Poderia falar um pouco do processo da sua empresa?"
        :maxLength="500"
      />
      <EditorHtml
        title="2º O que precisa que nosso sistema controle, quais são suas dificuldades?"
        :maxLength="500"
      />
    </Molded>
    <br />
    <Molded>
      <span class="title">Apresentação</span>

      <b-form-checkbox v-model="seq[1]">
        <span class="options" :class="{ done: seq[1] }">
          Informar que o software é em nuvem e pode ser acessado por qualquer
          navegador
        </span>
      </b-form-checkbox>

      <b-form-checkbox v-model="seq[2]">
        <span class="options" :class="{ done: seq[2] }">
          Mostrar o cadastro de cliente
          <span v-if="showIfByBussines('immediate')"
            >, limite de crédito e patrimônio</span
          >
        </span>
      </b-form-checkbox>

      <b-form-checkbox v-model="seq[3]">
        <span class="options" :class="{ done: seq[3] }">
          Mostrar o cadastro de produto , patrimônio e tabela de preço
        </span>
      </b-form-checkbox>

      <div
        v-if="showIfByBussines('programmed') || showIfByBussines('immediate')"
      >
        <div v-if="showIfByModules('rental')">
          <b-form-checkbox v-model="seq[4]">
            <span class="options" :class="{ done: seq[4] }">
              Gerar locação, adicionar 3 produto(s), organizar por grupos e
              mostrar que podem ser ordenados
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-model="seq[5]">
            <span class="options" :class="{ done: seq[5] }">
              Aprovar locação e mostrar como fica a impressão (informar que os
              documentos são dinâmicos)
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-if="showIfByModules('signature')" v-model="seq[6]">
            <span class="options" :class="{ done: seq[6] }">
              Enviar proposta para assinatura digital
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-model="seq[7]">
            <span class="options" :class="{ done: seq[7] }">
              Mostrar calendário, painel de OS, informar que a regra pode ser
              liberada somente para equipe operacional
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-model="seq[8]">
            <span class="options" :class="{ done: seq[8] }">
              Gerar saída pelo código de barras e adicionar mais produto(s),
              informando que além do que foi descrito na proposta, poderá enviar
              a mais de backup (mediante regra)
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-if="showIfByModules('billing')" v-model="seq[9]">
            <span class="options" :class="{ done: seq[9] }">
              Gerar faturamento
              <span v-if="showIfByModules('bankSlip')">
                com a opção da condição de pagamento por boleto
              </span>
              <span>, mostrar fatura impressa</span>
              <span v-if="showIfByModules('taxDocument')">
                , mostrar que pode gerar fatura de locação ou nota fiscal de
                serviço
              </span>
            </span>
          </b-form-checkbox>
        </div>

        <div v-if="showIfByModules('pdv')">
          <b-form-checkbox v-model="seq[10]">
            <span class="options" :class="{ done: seq[10] }">
              Gerar locação no PDV, informar que cada usuário precisa abrir seu
              caixa
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-model="seq[10]">
            <span class="options" :class="{ done: seq[10] }">
              Adicionar produto através do código de barras
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-model="seq[11]">
            <span class="options" :class="{ done: seq[11] }">
              Finalizar locacão, adicionar as condições de pagamento (caso não
              seja faturado)
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-if="showIfByModules('billing')" v-model="seq[12]">
            <span class="options" :class="{ done: seq[12] }">
              Se for faturar, marque a opção (Faturar) e poderá visualizar
              módulo faturamento
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-model="seq[13]">
            <span class="options" :class="{ done: seq[13] }">
              Mostrar documento impresso
            </span>
          </b-form-checkbox>

          <b-form-checkbox v-model="seq[14]">
            <span class="options" :class="{ done: seq[14] }">
              Mostrar o módulo de caixa, movimentações, abertura e fechamento
            </span>
          </b-form-checkbox>
        </div>

        <b-form-checkbox v-if="showIfByModules('finance')" v-model="seq[10]">
          <span class="options" :class="{ done: seq[10] }">
            Mostrar contas a pagar e receber
          </span>
        </b-form-checkbox>
      </div>
    </Molded>
    <br />
    <Molded>
      <div class="title">
        3º Focar nas dificuldades do cliente e passar pelas funções que
        conseguimos atender
      </div>
      <div class="title">4º Passar valores da mensalidade e documento(s)</div>
      <div class="title">
        5º O que achou? Vamos trabalhar juntos? Quando posso te retornar?
      </div>
    </Molded>

    <br /><br />
  </div>
</template>
<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";

export default {
  name: "Show",
  components: {
    Molded,
    CheckboxGroup,
    RadioGroup,
    InputText,
    EditorHtml,
  },
  data() {
    return {
      business: "programmed",
      modules: [],
      seq: [false],
    };
  },
  computed: {
    options() {
      let options = [
        /* {
          text: "Assinatura Digital",
          value: "signature",
          business: "all",
        },
        { text: "Boleto Bancário", value: "bankSlip", business: "billing" },*/
        { text: "Faturamento", value: "billing", business: "all" },
        /*  { text: "Financeiro", value: "finance", business: "all" }, */
        {
          text: "Locação (Programada)",
          value: "rental",
          business: "programmed immediate",
        },
        {
          text: "PDV (Frente de Loja)",
          value: "pdv",
          business: "immediate",
        },
        /*   { text: "Nota Fiscal", value: "taxDocument", business: "billing" }, */
      ];

      let final = [];

      var billing = this.modules.includes("billing") ? "billing" : "";

      options.forEach((options) => {
        if (
          options.business.includes(this.business) ||
          options.business == "all" ||
          options.business == billing
        ) {
          final.push(options);
        }
      });

      return final;
    },
  },
  methods: {
    showIfByBussines(value) {
      return this.business.includes(value);
    },
    showIfByModules(value) {
      return this.modules.includes(value);
    },
  },
  watch: {
    event: {
      handler(event) {},
      deep: true,
    },
  },
};
</script>
<style scoped>
.main {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.title {
  font-size: 18px;
}

.done {
  color: gray;
  text-decoration: line-through;
}

.options {
  font-size: 15px;
}
</style>